import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.slider_banner_single').slick({
		arrows: true,
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	});
	
	// $('.slider_temoignages_hp').slick({
	// 	arrows: false,
	// 	infinite: true,
	// 	slidesToShow: 3,
	// 	slidesToScroll: 3,
	// 	responsive: [
	// 		{
	// 			breakpoint: 1024,
	// 			settings: {
	// 				slidesToShow: 3,
	// 				slidesToScroll: 3
	// 			}
	// 		},
	// 		{
	// 			breakpoint: 600,
	// 			settings: {
	// 				slidesToShow: 2,
	// 				slidesToScroll: 2
	// 			}
	// 		},
	// 		{
	// 			breakpoint: 480,
	// 			settings: {
	// 				slidesToShow: 1,
	// 				slidesToScroll: 1
	// 			}
	// 		}
	// 	]
	// });

	/*
	$("div[class^=slider_column_]").each(function () {
		let id = $(this).attr('class').split("_").at(-1);
		$(".slider_column_" + id).slick({
			arrows: true,
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 4,
			prevArrow: $('.slider_column_prev_' + id),
			nextArrow: $('.slider_column_next_' + id),
			responsive: [
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});
	});
	*/


});